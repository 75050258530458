import React from 'react';
import { Link, navigate } from 'gatsby';
import { getProfile, isAuthenticated, logout } from '../services/auth';

import '../styles/nav-bar.scss';

const NavBar = ({ title }) => {
  
  return (
    <div className="nav-bar">
      <span className="title">
        <Link to="/">PDR Editorial</Link>
        {title && (
          ` – ${title}`
        )}
      </span>
      
      <nav>
        {isAuthenticated() ? (
          <>
            <Link to="/app/dashboard">Dashboard</Link>
            {` `}
            <Link to="/app/collections">Collections</Link>
            {` `}
            <Link to="/documentation">Docs</Link>
            {` `}
            <a
              href="/"
              onClick={event => {
                event.preventDefault();
                logout();
              }}
            >
              Log Out
            </a>
          </>
        ) : (
          <>
            <Link to="/app/dashboard">Log In</Link>
          </>
        )}
      </nav>
    </div>
  );
};

export default NavBar;