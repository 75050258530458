import React from 'react';
import Helmet from 'react-helmet';

import NavBar from './nav-bar';

import '../styles/global.scss';

const Layout = ({ noNavbar, title, children }) => (
  <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&family=Roboto:wght@400;500&display=swap" rel="stylesheet" />
      <title>{ `PDR Editorial${title ? ` - ${title}` : ''}` }</title>
    </Helmet>
    {(!noNavbar) && (
      <NavBar title={ title } />
    )}
    <div className="page-width">
      { children }
    </div>
  </>
);

export default Layout;